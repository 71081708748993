<template>
    <div>Esporta Categorie Evento CONI</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Categorie Evento CONI", route: "/settings/event-training-category-type/index" },
             { title: "Esporta Categorie Evento CONI" }
         ]);
     }
 };
</script>
